var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('page-section', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "titAlign": "text-center"
    }
  }, [_c('v-tabs-items', {
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab-item', {
    attrs: {
      "value": "form"
    }
  }, [_c('div', {
    staticClass: "mw-580px mx-auto"
  }, [_c('tit-wrap-dot', {
    attrs: {
      "title": "회원가입",
      "alignCenter": ""
    }
  }), _c('v-form', {
    model: {
      value: _vm.isValid,
      callback: function ($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }, [_c('div', {
    staticClass: "txt txt--xs text-right pb-16px pb-md-20px"
  }, [_c('span', {
    staticClass: "red--text"
  }, [_vm._v("* ")]), _vm._v("필수입력 항목입니다.")]), _c('vertical-form-table', _vm._b({
    on: {
      "certification": function (value) {
        return _vm.form._certification = value;
      }
    },
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }, 'vertical-form-table', {
    items: _vm.items
  }, false), [_c('template', {
    slot: "비밀번호 확인"
  }, [_c('v-password-confirm-field', {
    attrs: {
      "password": _vm.form.password,
      "placeholder": "동일한 비밀번호를 재입력하세요."
    }
  })], 1), _c('template', {
    slot: "연락처"
  }, [_c('user-contact-certification', _vm._b({
    attrs: {
      "dense": false,
      "btnAttrs": {
        className: 'w-100 min-w-sm-100px grey v-size--x-large'
      }
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.phone,
      callback: function ($$v) {
        _vm.$set(_vm.form, "phone", $$v);
      },
      expression: "form.phone"
    }
  }, 'user-contact-certification', Object.assign({}, _vm.attrs_input), false))], 1)], 2), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('terms-of-agreements-short', {
    on: {
      "isValid": function (isValid) {
        return _vm.isTermsValid = isValid;
      },
      "input": _vm.emit
    },
    model: {
      value: _vm.form._terms,
      callback: function ($$v) {
        _vm.$set(_vm.form, "_terms", $$v);
      },
      expression: "form._terms"
    }
  })], 1), _c('div', {
    staticClass: "btn-wrap btn-wrap--lg"
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large w-100 min-w-sm-200px",
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, 'v-btn', Object.assign({}, _vm.btn_secondary), false), [_vm._v("취소")])], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large w-100 min-w-sm-200px",
    on: {
      "click": _vm.join
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary, {
    disabled: _vm.disabled
  }), false), [_vm._v("회원가입")])], 1)], 1)], 1)], 1)], 1)]), _c('v-tab-item', {
    attrs: {
      "value": "result"
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "mb-18px mb-md-24px"
  }, [_c('icon-check')], 1), _c('div', {
    staticClass: "tit"
  }, [_vm._v("기업 회원가입 요청이 완료되었습니다.")]), _c('div', {
    staticClass: "txt txt--sm mt-8px mt-md-12px"
  }, [_vm._v("관리자 승인 후 사용 가능합니다.")]), _c('div', {
    staticClass: "btn-wrap btn-wrap--lg"
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large w-100 min-w-sm-200px",
    attrs: {
      "to": "/"
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("메인으로 이동")])], 1)], 1)], 1)])])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }