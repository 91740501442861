var render = function render(){
  var _vm$btnAttrs, _vm$btnAttrs2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', [_c('v-row', {
    staticClass: "row--sm ma-md-n6px"
  }, [_c('v-col', {
    staticClass: "pa-md-6px",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', [_c('v-text-field', _vm._b({
    class: _vm.$attrs.className,
    attrs: {
      "readonly": _vm.isConfirmed
    },
    model: {
      value: _vm.contact,
      callback: function ($$v) {
        _vm.contact = $$v;
      },
      expression: "contact"
    }
  }, 'v-text-field', Object.assign({}, _vm.$attrs, {
    placeholder: _vm.placeholder,
    rules: _vm.rules
  }), false))], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    class: (_vm$btnAttrs = _vm.btnAttrs) === null || _vm$btnAttrs === void 0 ? void 0 : _vm$btnAttrs.className,
    attrs: {
      "disabled": _vm.isConfirmed
    },
    on: {
      "click": _vm.certify
    }
  }, 'v-btn', _vm.btnAttrs, false), [_vm._v("인증요청")])], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-md-6px",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', [_c('v-text-field', _vm._b({
    class: _vm.$attrs.className,
    attrs: {
      "placeholder": "인증번호를 입력하세요.",
      "type": "number",
      "readonly": _vm.isConfirmed,
      "counter": ""
    },
    model: {
      value: _vm.otp,
      callback: function ($$v) {
        _vm.otp = $$v;
      },
      expression: "otp"
    }
  }, 'v-text-field', _vm.$attrs, false), [_c('template', {
    slot: "counter"
  }, [_vm._v(" " + _vm._s("") + " "), !_vm.isConfirmed && _vm.expiredAt ? _c('count-down', {
    attrs: {
      "value": _vm.expiredAt
    },
    on: {
      "ended": function ($event) {
        _vm.isExpired = true;
      }
    }
  }) : _vm._e()], 1)], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    class: (_vm$btnAttrs2 = _vm.btnAttrs) === null || _vm$btnAttrs2 === void 0 ? void 0 : _vm$btnAttrs2.className,
    attrs: {
      "disabled": _vm.isConfirmed || _vm.isExpired
    },
    on: {
      "click": _vm.confirm
    }
  }, 'v-btn', _vm.btnAttrs, false), [_vm._v("인증")])], 1)], 1)], 1)], 1), _c('v-input', {
    attrs: {
      "value": _vm.isConfirmed,
      "hidden": "",
      "rules": [function (value) {
        return value;
      }],
      "hide-details": ""
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }