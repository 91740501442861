var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    staticClass: "icon-svg icon-check",
    attrs: {
      "width": "60",
      "height": "60",
      "viewBox": "0 0 60 60",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', {
    attrs: {
      "clip-path": "url(#clip0_492_11998)"
    }
  }, [_c('path', {
    staticStyle: {
      "fill": "#2a2b2e !important"
    },
    attrs: {
      "d": "M30 60C13.4573 60 0 46.5427 0 30C0 13.4573 13.4573 0 30 0C46.5427 0 60 13.4573 60 30C60 46.5427 46.5427 60 30 60ZM30 3.75C15.5251 3.75 3.75 15.5251 3.75 30C3.75 44.4749 15.5251 56.25 30 56.25C44.4749 56.25 56.25 44.4749 56.25 30C56.25 15.5251 44.4749 3.75 30 3.75Z"
    }
  }), _c('path', {
    staticStyle: {
      "fill": "#013ca6 !important"
    },
    attrs: {
      "d": "M27.1869 40.0001C26.7067 40.0001 26.227 39.8179 25.8617 39.4503L17.7368 31.3254C17.0044 30.5926 17.0044 29.4051 17.7368 28.6727C18.4692 27.9403 19.6567 27.9403 20.3896 28.6727L27.1892 35.4728L42.1146 20.5479C42.847 19.8154 44.0344 19.8154 44.7668 20.5479C45.4993 21.2803 45.4993 22.4677 44.7668 23.2001L28.5167 39.4503C28.1468 39.8179 27.6671 40.0001 27.1869 40.0001Z"
    }
  })]), _c('defs', [_c('clipPath', {
    attrs: {
      "id": "clip0_492_11998"
    }
  }, [_c('rect', {
    attrs: {
      "width": "60",
      "height": "60",
      "fill": "white"
    }
  })])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }