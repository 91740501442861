<template>
    <v-password-field v-model="passwordConfirm" v-bind="{ ...$attrs, rules }" outlined hide-details="auto" />
</template>
<script>
import VPasswordField from "./v-password-field.vue";
export default {
    components: {
        VPasswordField,
    },
    props: {
        password: { type: String, default: null },
        isModify: { type: Boolean, default: false },
    },
    data: () => ({
        passwordConfirm: null,
    }),
    computed: {
        rules() {
            return [() => (this.isModify && !this.password) || (this.passwordConfirm && this.passwordConfirm == this.password) || "비밀번호가 일치하지 않습니다"];
        },
    },
};
</script>
