<template>
    <client-page>
        <page-section titAlign="text-center" class="page-section--first page-section--last">
            <v-tabs-items v-model="tab">
                <!-- 회원가입정보입력 -->
                <v-tab-item value="form">
                    <div class="mw-580px mx-auto">
                        <tit-wrap-dot title="회원가입" alignCenter />
                        <v-form v-model="isValid">
                            <div class="txt txt--xs text-right pb-16px pb-md-20px"><span class="red--text">* </span>필수입력 항목입니다.</div>
                            <vertical-form-table v-model="form" v-bind="{ items }" @certification="(value) => (form._certification = value)">
                                <template slot="비밀번호 확인">
                                    <v-password-confirm-field :password="form.password" placeholder="동일한 비밀번호를 재입력하세요." />
                                </template>

                                <template slot="연락처">
                                    <user-contact-certification v-model="form.phone" v-bind="{ ...attrs_input }" :dense="false" :btnAttrs="{ className: 'w-100 min-w-sm-100px grey v-size--x-large' }" @input="emit" />
                                </template>
                            </vertical-form-table>

                            <div class="btn-wrap">
                                <terms-of-agreements-short v-model="form._terms" @isValid="(isValid) => (isTermsValid = isValid)" @input="emit" />
                            </div>

                            <div class="btn-wrap btn-wrap--lg">
                                <v-row justify="center" class="row--sm">
                                    <v-col cols="6">
                                        <v-btn v-bind="{ ...btn_secondary }" class="v-size--xx-large w-100 min-w-sm-200px" @click="$router.go(-1)">취소</v-btn>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-btn v-bind="{ ...btn_primary, disabled }" class="v-size--xx-large w-100 min-w-sm-200px" @click="join">회원가입</v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-form>
                    </div>
                </v-tab-item>
                <!-- 회원가입완료 -->
                <v-tab-item value="result">
                    <div class="text-center">
                        <div class="mb-18px mb-md-24px">
                            <icon-check />
                        </div>
                        <div class="tit">기업 회원가입 요청이 완료되었습니다.</div>
                        <div class="txt txt--sm mt-8px mt-md-12px">관리자 승인 후 사용 가능합니다.</div>

                        <div class="btn-wrap btn-wrap--lg">
                            <v-row justify="center" class="row--sm">
                                <v-col cols="6" sm="auto">
                                    <v-btn v-bind="{ ...btn_primary }" to="/" class="v-size--xx-large w-100 min-w-sm-200px">메인으로 이동</v-btn>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </v-tab-item>
            </v-tabs-items>
        </page-section>
    </client-page>
</template>

<script>
import api from "@/api";
import CryptoAES from "@/plugins/crypto-aes";
import { btn_primary, btn_secondary, btn_tertiary, initUser, rules, attrs_input } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import TitWrapDot from "@/components/client/dumb/tit-wrap-dot.vue";
import VAddressField from "@/components/plugins/vuetify/v-address-field.vue";
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";
import VPasswordConfirmField from "@/components/plugins/vuetify/v-password-confirm-field.vue";
import TermsOfAgreementsShort from "@/components/client/join/terms-of-agreements-short.vue";
import IconCheck from "@/components/client/icons/icon-check.vue";
import UserContactCertification from "@/components/dumb/user-contact-certification.vue";

const items = [
    {
        key: "username",
        term: "아이디",
        type: "text",
        required: true,
        placeholder: "영문,숫자 포함 3자 이상 입력해주세요.",
        rules: rules.username,
    },
    {
        key: "password",
        term: "비밀번호",
        type: "password",
        required: true,
        placeholder: "영문,숫자,특수기호 포함 8자 입력해주세요.",
        autocomplete: "new-password",
        rules: rules.password,
    },
    {
        key: "password",
        term: "비밀번호 확인",
        required: true,
        placeholder: "동일한 비밀번호를 재입력하세요.",
    },
    {
        key: "phone",
        term: "연락처",
        type: "phone",
        required: true,
        // hasCertification: true,
        // placeholder: "‘-’를 제외하고 입력하세요.",
        // btnAttrs: { className: "w-100 min-w-sm-100px grey" },
    },
    {
        key: "bizNumber",
        term: "사업자등록번호",
        type: "text",
        required: true,
        placeholder: "사업자등록번호를 입력하세요.",
        rules: rules.bizNumber,
    },
    {
        key: "businessRegistration",
        term: "사업자등록증",
        type: "file",
        required: true,
        rules: rules.businessRegistration,
    },
    // {
    //     key: "nicepayUserName",
    //     term: "본인인증 이름 (주문 시 사용)",
    //     type: "text",
    // },
    // {
    //     key: "nicepayUserPhone",
    //     term: "본인인증 연락처 (주문 시 사용)",
    //     type: "phone",
    // },
    // {
    //     key: "name",
    //     term: "이름",
    //     type: "text",
    //     required: true,
    //     placeholder: "이름을 입력하세요.",
    //     rules: rules.name,
    // },
    // {
    //     key: "email",
    //     term: "이메일",
    //     type: "email",
    //     required: true,
    //     placeholder: "이메일을 입력하세요.",
    //     rules: rules.email,
    // },
    // {
    //     term: "주소",
    //     type: "address",
    //     required: true,
    // },
    // {
    //     key: "promoterCode",
    //     term: "추천인코드",
    //     type: "text",
    //     required: true,
    //     placeholder: "추천인코드를 입력하세요.",
    //     rules: rules.promoterCode,
    // },
].map((item) => ({ ...item, outlined: true }));

export default {
    components: {
        ClientPage,
        PageSection,
        TitWrapDot,
        VAddressField,
        VerticalFormTable,
        VPasswordConfirmField,
        TermsOfAgreementsShort,
        IconCheck,
        UserContactCertification,
    },
    data: () => ({
        btn_primary,
        btn_secondary,
        btn_tertiary,

        attrs_input,

        form: initUser(),

        tab: "form",
        // tab: "result",

        items,
        isValid: false,
        isTermsValid: undefined,
    }),
    computed: {
        promoterCode() {
            return this.$route.query.promoterCode || null;
        },
        disabled() {
            return !this.isValid || !this.isTermsValid;
        },
    },
    mounted() {
        let { promoterCode } = this;
        this.form = initUser({ promoterCode });
    },
    methods: {
        validates() {
            try {
                switch (this.isTermsValid) {
                    case undefined:
                        throw new Error("이용약관을 확인해주세요");
                    case false:
                        throw new Error("필수 이용약관에 모두 동의해주세요");
                    default:
                        break;
                }
                return true;
            } catch (error) {
                alert(error.message);
                return false;
            }
        },
        async join() {
            if (!this.validates()) return;
            if (this.loading) return;
            this.loading = true;

            try {
                let { _businessRegistration, businessRegistration, ...form } = this.form;
                form.password = CryptoAES.encrypt(this.form.password);
                await api.v1.users.post(form);

                await this.$store.dispatch("login", { username: this.form.username, password: this.form.password });

                // POST resource
                if (businessRegistration instanceof File) {
                    businessRegistration = (await api.v1.files.post({ path: "businessRegistration" }, businessRegistration))?.file;
                }
                _businessRegistration = businessRegistration?._id || null;
                await api.v1.me.put({ _businessRegistration });

                this.tab = "result";
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        emit() {
            this.form = initUser(this.form);
        },
    },
};
</script>

<style lang="scss" scoped>
.v-btn-toggle {
    width: 100%;
    border-radius: var(--btn-border-radius-xxl);
    overflow: hidden;
    > .v-btn.v-btn {
        margin: 0;
        border-radius: 0;
        opacity: 1;
        border-width: var(--btn-outline-border-width);
        background-color: #fff !important;
        border-color: inherit;
        &--active {
            color: #fff !important;
            background-color: inherit !important;
            &:before {
                opacity: 0;
            }
        }
        &.v-size--xx-large {
            &:first-child {
                border-top-left-radius: var(--btn-border-radius-xxl);
                border-bottom-left-radius: var(--btn-border-radius-xxl);
            }
            &:last-child {
                border-top-right-radius: var(--btn-border-radius-xxl);
                border-bottom-right-radius: var(--btn-border-radius-xxl);
            }
        }
    }
}
</style>
