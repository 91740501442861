<template>
    <span>{{ text }}</span>
</template>

<script>
import dayjs from "dayjs";
export default {
    props: {
        value: { type: [String, Date], default: null },
        mode: { type: String, default: null },
    },
    data() {
        return {
            text: this.getText(this.value),
            interval: null,
        };
    },
    mounted() {
        this.interval = setInterval(this.setText, 1000);
    },
    destroyed() {
        if (this.interval !== null) {
            clearInterval(this.interval);
            this.interval = null;
        }
    },
    methods: {
        setText() {
            this.text = this.getText(this.value);
        },
        getText(time) {
            switch (this.mode) {
                case "past": {
                    return this.getTimePast(time);
                }

                default: {
                    return this.getTimeLeft(time);
                }
            }
        },

        getTimePast(time) {
            return time?.toAgo?.();
        },
        getTimeLeft(time) {
            const now = dayjs();
            const value = dayjs(time);

            const diff = value.diff(now, "day", true);
            if (diff < 0) {
                return "0일 0시 0분 0초";
            }

            const d = Math.floor(diff);
            const h = Math.floor((diff - d) * 24);
            const m = Math.floor(((diff - d) * 24 - h) * 60);
            const s = Math.floor((((diff - d) * 24 - h) * 60 - m) * 60);

            return `${d}일 ${h}시 ${m}분 ${s}초`;
        },
    },
};
</script>

<style></style>
