<template>
    <v-sheet>
        <v-row class="row--sm ma-md-n6px">
            <v-col cols="12" class="pa-md-6px">
                <v-row class="row--xs">
                    <v-col>
                        <v-text-field v-model="contact" v-bind="{ ...$attrs, placeholder, rules }" :class="$attrs.className" :readonly="isConfirmed" />
                    </v-col>
                    <v-col cols="auto">
                        <v-btn v-bind="btnAttrs" :class="btnAttrs?.className" :disabled="isConfirmed" @click="certify">인증요청</v-btn>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" class="pa-md-6px">
                <v-row class="row--xs">
                    <v-col>
                        <v-text-field placeholder="인증번호를 입력하세요." v-model="otp" v-bind="$attrs" type="number" :readonly="isConfirmed" :class="$attrs.className" counter>
                            <template slot="counter"> {{ "" }} <count-down v-if="!isConfirmed && expiredAt" :value="expiredAt" @ended="isExpired = true" /> </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn v-bind="btnAttrs" :class="btnAttrs?.className" :disabled="isConfirmed || isExpired" @click="confirm">인증</v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-input :value="isConfirmed" hidden :rules="[(value) => value]" hide-details />
        <!-- <v-text-field v-model="contact" v-bind="{ ...$attrs, placeholder, rules }" :class="$attrs.className" :readonly="isConfirmed">
            <v-btn slot="append-outer" v-bind="btnAttrs" :class="btnAttrs?.className" :disabled="isConfirmed" @click="certify">인증하기</v-btn>
        </v-text-field>
        <v-text-field placeholder="인증번호를 입력하세요." v-model="otp" v-bind="$attrs" type="number" :readonly="isConfirmed" :class="$attrs.className" counter>
            <template slot="counter"> {{ "" }} <count-down v-if="!isConfirmed && expiredAt" :value="expiredAt" @ended="isExpired = true" /> </template>
            <v-btn slot="append-outer" v-bind="btnAttrs" :class="btnAttrs?.className" :disabled="isConfirmed || isExpired" @click="confirm">인증완료</v-btn>
        </v-text-field>
        <v-input :value="isConfirmed" hidden :rules="[(value) => value]" hide-details /> -->
    </v-sheet>
</template>

<script>
import api from "@/api";
import CountDown from "./count-down.vue";
import { rules } from "@/assets/variables";

export default {
    components: {
        CountDown,
    },
    props: {
        type: { type: String, default: "phone" },
        value: { type: String, default: null },
        btnAttrs: { type: Object, default: () => ({}) },
        placeholder: { type: String, default: "‘-’를 제외하고 입력하세요." },
    },
    data: () => ({
        rules: rules.phone,

        otp: null,
        contact: null,

        expiredAt: null,
        isExpired: false,
        isConfirmed: false,
    }),
    computed: {
        phone() {
            return (this.contact || "").replaceAll(/\D/g, "");
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
        isConfirmed() {
            this.$emit("input", this.contact);
        },
    },
    methods: {
        sync() {
            if (this.value != this.contact) this.contact = this.value;
        },
        async certify() {
            let { type, phone } = this;
            try {
                if (type == "phone") {
                    if (![10, 11].includes(phone.length)) throw new Error("잘못된 요청입니다");
                }
            } catch (error) {
                alert(error.message);
                return;
            }

            if (type == "phone") {
                let { expiredAt } = await api.v1.users.contact.certify({ type, phone });
                this.expiredAt = expiredAt;
                this.isExpired = false;
            }
        },
        async confirm() {
            let { otp, type, phone } = this;
            try {
                if (otp.length != 6) throw new Error("인증번호는 6자입니다");
            } catch (error) {
                alert(error.message);
                return;
            }

            if (type == "phone") {
                await api.v1.users.contact.confirm({ otp, type, phone });
                this.isConfirmed = true;
            }
        },
    },
};
</script>
<style lang="scss" scoped>
::v-deep {
    .v-input__append-outer {
        margin-top: 0;
        margin-bottom: 0;
    }
}
</style>
